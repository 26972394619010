const players = [];
$('.player').each(function (i, e) {
  $(this).attr('id', 'player_' + i);
  var url = $(this).attr('data-url');
  var playerInitialized = false;
  var statsQuadrantsPos = {};
  var player;
  WP.push(function () {
    player = newPlayer(url, i);
    playerStats(player, playerInitialized, statsQuadrantsPos);
    players.push(player);
  });
});

document.querySelectorAll('.video--container').forEach((el, i) => {
  // create fullscreen button for each player
  const btnFull = document.createElement('button');
  btnFull.classList.add('btn--fullscreen');
  el.appendChild(btnFull);

  // on fullscreen button click create new fullscreen player on forced horizontal position
  btnFull.addEventListener('click', function () {
    const articles = Array.prototype.slice.call(
      document.querySelectorAll('.article__wrapper')
    );
    const idx = articles.indexOf(this.closest('.article__wrapper'));
    const playerPosition = players[idx - 1].getPosition();
    players[idx - 1].pause();
    setSize();
    const url = this.closest('.video--container')
      .querySelector('.player')
      .getAttribute('data-url');

    const videoHorizontal = document.createElement('div');
    const close = document.createElement('button');
    close.classList.add('video--close');
    videoHorizontal.classList.add('video--horizontal');
    videoHorizontal.innerHTML = `<div class="player-h" id="player_h"></div>`;
    document.querySelector('body').appendChild(videoHorizontal);
    document.querySelector('body').appendChild(close);
    document.documentElement.classList.add('horizontal-mode--fixed');

    const playerH = newPlayer(url, 'h');
    playerStats(playerH, false, {}, playerPosition);

    // remove player on close button click
    close.addEventListener('click', function () {
      const playerHposition = playerH.getPosition();
      players[idx - 1].setPosition(playerHposition);
      playerH.destroy();
      this.remove();
      document.documentElement.classList.remove('horizontal-mode--fixed');
      document.querySelector('.video--horizontal').remove();
    });

    // remove player on orientation change if orientation is landscape
    const getOrientation = () => {
      if (window.innerWidth > window.innerHeight) {
        const playerHposition = playerH.getPosition();
        players[idx - 1].setPosition(playerHposition);
        playerH.destroy();
        document.documentElement.classList.remove('horizontal-mode--fixed');
        document.querySelector('.video--horizontal').remove();
        document.querySelector('.video--close').remove();
      }
    };

    window.addEventListener('orientationchange', debounce(getOrientation, 500));
  });
});

function newPlayer(url, i) {
  return new WP.player({
    url: url,
    target: 'player_' + i,
    adv: false,
    hiderelated: true,
    extendedrelated: false,
    floatingplayer: false,
    autoplay: true,
    forceliteembed: true,
    extendedfullsizeenabled: true,
  });
}

function playerStats(
  player,
  playerInitialized,
  statsQuadrantsPos,
  playerPosition
) {
  var clipName, clipMid;
  player.on('INIT', function () {
    if (!playerInitialized) {
      playerInitialized = true;
      this.setVolume(0.5);
    }
  });
  player.on('PLAYER_LOADED', function () {
    //
  });
  player.on('START_MOVIE', function () {
    clipName = player.getClipData().title;
    clipMid = player.getClipData().mid;
    sendStats('video', 'view', clipName);
    // console.log('start movie', playerPosition, player.getPosition());
    if (playerPosition && playerPosition > 0) {
      player.setPosition(playerPosition);
    }
  });
  // player.on('CHANGE_STATE', function () {
  // });
  // player.on('CHANGE_FULLSCREEN', function () {
  //   if (
  //     this.closest('.wp-player').classList.contains('fullscreen') &&
  //     this.getBoundingClientRect().height !== window.innerHeight
  //   ) {
  //     ScrollTrigger.disable();
  //     this.closest('.video--container').style.transform = 'unset';
  //   } else {
  //     ScrollTrigger.enable();
  //   }
  // });

  player.on('END_MOVIE', function () {
    sendStats('video', 'end', clipName);
  });
  player.on('UPDATE_POSITION', function (e) {
    var position = player.getPosition();
    statsQuadrants(position);
  });
  var statsQuadrants = function (p) {
    p = Math.round(p * 100);
    if (!statsQuadrantsPos[clipMid]) {
      statsQuadrantsPos[clipMid] = {};
    }
    if (p >= 25 && p < 50) {
      if (!statsQuadrantsPos[clipMid][25]) {
        statsQuadrantsPos[clipMid][25] = 1;
        sendStats('video', 'quarter=25', clipName);
      }
    } else if (p >= 50 && p < 75) {
      if (!statsQuadrantsPos[clipMid][50]) {
        statsQuadrantsPos[clipMid][50] = 1;
        sendStats('video', 'quarter=50', clipName);
      }
    } else if (p >= 75 && p < 100) {
      if (!statsQuadrantsPos[clipMid][75]) {
        statsQuadrantsPos[clipMid][75] = 1;
        sendStats('video', 'quarter=75', clipName);
      }
    }
  };
}
