const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    document.querySelectorAll('.btn--fullscreen').forEach((el) => {
      el.classList.add('btn--fullscreen--visible');
    });
  }
  if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    document.querySelectorAll('.btn--fullscreen').forEach((el) => {
      el.classList.add('btn--fullscreen--visible');
    });
  }
};

deviceType();
