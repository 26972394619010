// function initializeClock(gallery, startTime, endTime) {
//   function updateClock() {
//     if (new Date() >= startTime && new Date() <= endTime) {
//       gallery.classList.add('gallery--visible');
//     } else {
//       gallery.classList.remove('gallery--visible');
//     }
//
//     if (new Date() > endTime) {
//       clearInterval(timeinterval);
//     }
//     if (
//       document.querySelector('#live') &&
//       document.querySelectorAll('.gallery--visible').length < 1
//     ) {
//       document.querySelector('#live').classList.add('live--finished');
//     }
//     if (
//       document.querySelector('.article__live') &&
//       document.querySelectorAll('.gallery--visible').length < 1
//     ) {
//       document.querySelector('.article__live').classList.add('live--finished');
//     }
//   }
//
//   updateClock();
//   const timeinterval = setInterval(updateClock, 1000);
// }
//
// if (document.querySelectorAll('.owl-cdc').length > 0) {
//   const times = [];
//   document.querySelectorAll('.owl-cdc').forEach(function (slider, i) {
//     if (slider.getAttribute('data-start') && slider.getAttribute('data-end')) {
//       const dateStart = slider.getAttribute('data-start');
//       const dateEnd = slider.getAttribute('data-end');
//       const [y, m, d, h, mi, s] = dateStart.split(',').map(Number);
//       const [yE, mE, dE, hE, miE, sE] = dateEnd.split(',').map(Number);
//
//       const start = new Date(y, m - 1, d, h, mi, s);
//       const end = new Date(yE, mE - 1, dE, hE, miE, sE);
//       if (!(new Date() > end)) {
//         times.push(end);
//       }
//
//       if (new Date() > start && new Date() < end) {
//         slider.classList.add('gallery--visible');
//       } else {
//         slider.classList.remove('gallery--visible');
//       }
//       if (new Date() < end) {
//         initializeClock(slider, start, end);
//       }
//     }
//   });
//   if (times.length < 1) {
//     if (document.querySelector('#live')) {
//       document.querySelector('#live').classList.add('live--finished');
//     }
//     if (document.querySelector('.article__live')) {
//       document.querySelector('.article__live').classList.add('live--finished');
//     }
//   }
// }
