const createHero = function () {
    const html = ``;
    /*const html = `
      <img
        class="bg-circle-l rellax"
        src="https://komputronik-lp-liveshopping-2023.wpcdn.pl/img/bg/bg-circle-l.svg"
        alt=""
        data-rellax-speed="-3"
      />
      <img
        class="bg-circle-lines-l rellax"
        src="https://komputronik-lp-liveshopping-2023.wpcdn.pl/img/bg/bg-circle-lines-l.svg"
        alt=""
        data-rellax-speed="2"
      />
      <img
        class="bg-circle-r rellax"
        src="https://komputronik-lp-liveshopping-2023.wpcdn.pl/img/bg/bg-circle-r.svg"
        alt=""
        data-rellax-speed="1"
      />
      <img
        class="bg-circle-lines-r rellax"
        src="https://komputronik-lp-liveshopping-2023.wpcdn.pl/img/bg/bg-circle-lines-r.svg"
        alt=""
        data-rellax-speed="-5"
      />
      <img
        class="computer"
        src="https://komputronik-lp-liveshopping-2023.wpcdn.pl/img/bg/computer.png"
        alt=""
      />
    `;*/
    if (document.getElementById('strona-glowna')) {
        const bg = document.createElement('div');
        bg.classList.add('intro-bg');
        bg.innerHTML = html;
        document.getElementById('strona-glowna').prepend(bg);
    }
    if (document.querySelectorAll('.live__img').length > 0) {
        document.querySelectorAll('.live__img').forEach(el => {
            const bg = document.createElement('div');
            bg.classList.add('intro-bg');
            bg.innerHTML = html;
            el.prepend(bg);
        });
    }
  
};

createHero();
const rellax = new Rellax('.rellax');
