'use strict';

$.extend($.easing, {
  easeOutExpo: function (x, t, b, c, d) {
    return t == d ? b + c : c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
  },
  easeInOutExpo: function (x, t, b, c, d) {
    if (t == 0) return b;
    if (t == d) return b + c;
    if ((t /= d / 2) < 1) return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
    return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
  },
});

//Obiekt zbierający informacje o elementach typu banner, których statystyki należy wysłać w unload
var statElementCollector = new Object();

var ga360statElementsInit = function (el) {
  alert($(el).data('ga360-id'));
};

var slidePositionVieved = new Object();
//owlstats ga360 slider stats slider

function ga360Test() {
  var statElementArray = [];

  for (var pos in statElementCollector) {
    statElementArray.push(statElementCollector[pos]);
  }
  // console.log(statElementArray);
}

var viewData = [];

// flaga dla urządzeń z touch:
var deviceTouch;
if ('ontouchstart' in document.documentElement) {
  deviceTouch = true;
}

// flaga dla scrollowania:
var isScrolligFlag = false;
var idTS;

window.addEventListener('scroll', function () {
  isScrolligFlag = true;
  if (idTS) {
    clearTimeout(idTS);
  }
  idTS = setTimeout(function () {
    idTS = null;
    isScrolligFlag = false;
  }, 450);
});

const debounce = (func, delay = 1000) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
