const cutWords = function (selector, length) {
  if (document.querySelectorAll(selector).length > 0) {
    document.querySelectorAll(selector).forEach((el) => {
      if (el.innerText.length > length) {
        el.innerText = el.innerText.substr(0, length) + '\u2026';
      }
    });
  }
};

cutWords('.box__title', 80);
cutWords('.box__lead', 180);
