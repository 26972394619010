// const animatedElements = document.querySelectorAll(
//   '._js_inViewport, .__js_preload'
// );
//
// const obsCallback = function (entries, observer) {
//   entries.forEach((entry) => {
//     if (entry.isIntersecting) {
//       if (entry.target.classList.contains('_js_inViewport')) {
//         entry.target.classList.add('__js_onScreen');
//         console.log(entry.target);
//       }
//       if (entry.target.classList.contains('__js_preload')) {
//         const srcS = entry.target.getAttribute('data-src');
//         $(entry.target)
//           .css('opacity', '.3')
//           .attr('src', srcS)
//           .animate({ opacity: 1 }, 250);
//         entry.target.classList.remove('__js_preload');
//         observer.unobserve(entry.target);
//       }
//       if (entry.target.classList.contains('article__wrapper')) {
//         entry.target.classList.add('section--fixed');
//       }
//     } else {
//       if (
//         entry.target.classList.contains('_js_inViewport') &&
//         entry.target.classList.contains('__js_onScreen')
//       ) {
//         entry.target.classList.remove('__js_onScreen');
//         console.log(entry.target);
//         // observer.unobserve(entry.target);
//       }
//     }
//   });
// };
//
// const observer = new IntersectionObserver(obsCallback, {
//   root: null,
//   threshold: 0,
//   // rootMargin: `-${marginObs}px`,
// });
//
// animatedElements.forEach((elem) => {
//   observer.observe(elem);
// });
