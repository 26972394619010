const setSectionBgSize = function () {
  document.querySelectorAll('.article__wrapper').forEach(function (el, i) {
    if (el.querySelectorAll('.video--container').length > 0) {
      document.documentElement.style.setProperty(
        '--translateBg',
        `${
          el.querySelectorAll('.video--container')[0].getBoundingClientRect()
            .height / 2
        }px`
      );
    }
  });
};

const setSize = function () {
  document.documentElement.style.setProperty(
    '--browserArea',
    window.innerHeight + 'px'
  );
};

document.querySelectorAll('.article__wrapper').forEach(function (el, i) {
  if (el.querySelectorAll('.article__title').length > 0) {
    const title = el.querySelectorAll('.article__title')[0];
    const string = title.innerText;
    title.innerHTML = `
    <span>${string.slice(0, 1)}</span>${string.slice(1, string.length)}
    `;
  }
});

setSectionBgSize();
setSize();

window.addEventListener('resize', (event) => {
  setSectionBgSize();
  setSize();
});
