const makeVideoSticky = function () {
  document.querySelectorAll('.video--container').forEach((el) => {
    // const distance =
    //   el.closest('.article__wrapper').getBoundingClientRect().height -
    //   window.innerHeight;
    // el.getBoundingClientRect().height - 300;
    ScrollTrigger.create({
      trigger: el,
      start: `top ${
        document.querySelector('.header').getBoundingClientRect().height
      }px`,
      ////end: `+=${distance}`,
      end: 'bottom',
      // toggleClass: 'video--fixed',
      // markers: true,
      pin: true,
    });
  });
};

ScrollTrigger.matchMedia({
  '(orientation: portrait) and (max-width: 768px)': function () {
    makeVideoSticky();
  },
  '(orientation: portrait) and (min-width: 769px)': function () {
    makeVideoSticky();
  },
});
