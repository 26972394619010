//share facebook, twitter, ga360
(function () {
  // document.querySelectorAll('.social__btn').forEach(function (el) {
  //   el.insertAdjacentHTML(
  //     'afterbegin',
  //     '<span class="share-container">\n' +
  //       '          <span class="share-top"></span>\n' +
  //       '          <span class="share-mid"></span>\n' +
  //       '          <span class="share-bottom"></span>\n' +
  //       '        </span>'
  //   );
  // });

  $('.__js_socialShare').click(function (e) {
    e.preventDefault();

    var categoryName = $(this).data('id'),
      href = $(this).data('href'),
      $aID = $(this).parents('.__js_stats_wrapper').data('id'),
      ga360Socials;

    if ($aID == null) {
      $aID = 'footer';
    }

    sendStats('share-' + categoryName, 'click', $aID);

    // do 360 mają być inne nazwy
    if (categoryName === 'facebook') {
      window.open(
        href,
        '_blank',
        'top=100, left=300, width=320,height=320,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_FB';
    }
    if (categoryName === 'instagram') {
      window.open(
        href,
        '_blank',
        'top=100, left=300, width=320,height=320,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_Instagram';
    }
    if (categoryName === 'twitter') {
      window.open(
        href,
        '_blank',
        'top=100, left=300, width=320,height=320,toolbar=1,resizable=0'
      );
      ga360Socials = 'share_Twitter';
    }
    WP.push(function () {
      WP.pwp.customAction({
        ga: {
          event: 'site_interaction',
          eventCategory: ga360Socials,
          eventAction: 'click',
        },
      });
    });
  });

  document.querySelectorAll('.social__btn').forEach(function (el) {
    el.addEventListener('click', function () {
      el.closest('.socials__buttons').classList.toggle('socials--expanded');
      el.closest('.socials__wrapper')
        .querySelector('.socials__text')
        .classList.toggle('socials__text--translated');
    });
  });
})();
