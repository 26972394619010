var activeSlug;
var isScrolling = false, // gdy strona się scrolluje to zmienna ma wartość true i nie wysyłamy statów na hover np. akordeon, hotpoint
  lastReportedSlug = null;
var wp_content_selector;
var customOffset = $('.header').height();
var BASE_URL;

if (typeof serviceBaseUrl !== 'undefined') {
  BASE_URL = '/' + serviceBaseUrl.split('/')[3] + '/';
} else {
  BASE_URL = '';
}

window.addEventListener('resize', (event) => {
  customOffset = $('.header').height();
});

(function () {
  if (
    !document.getElementsByClassName('__js_stats_wrapper').length ||
    document.location.href.indexOf('.html') > 0
  )
    return;

  var slugs = [];
  $('.__js_stats_wrapper').each(function (i, el) {
    slugs.push($(el).attr('id'));
  });

  var pathname;
  if (slugs.indexOf(document.location.pathname.split('/').pop()) > -1) {
    pathname = document.location.pathname.split('/').pop();
  }

  var $sections = $('.__js_stats_wrapper'),
    initSlug = pathname || $('.__js_stats_wrapper').eq(0).attr('id');

  activeSlug = initSlug;
  // var customOffset = $('.header').height() + 1,
  var animationTime = 800,
    // Przewijanie do sekcji slug jeżeli w URL jest ustawiony jakiś slug na starcie

    scrollOnStart = function () {
      if (getActiveSlugIndex() === 0) {
        $('html,body').animate(
          {
            scrollTop: $('#' + initSlug).offset().top - customOffset,
          },
          animationTime,
          'easeOutExpo'
        );
      } else {
        $('html,body').animate(
          {
            scrollTop: $('#' + initSlug).offset().top - customOffset + 2,
          },
          animationTime,
          'easeOutExpo'
        );
      }

      sendStats('progress', 'start', initSlug);

      changeActiveSlug(initSlug);
      initNavigation();
    },
    // staty pageviews i scrollboost pwp ----- {

    reportSlugTS = -1,
    reportSlug = function () {
      if (lastReportedSlug === null) {
        //wyślij statystykę pageview standard
        if (typeof statData == 'undefined' && !statData.length) {
          console.log(
            '%c brakuje statData lub brak danych do statystyk ga360',
            'background: #553311; padding: 3px; color: #ffaaaa'
          );
          return;
        }
        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageview',
              page: {
                pageviewType: 'standard',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: asGA30,
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
            },
          });
        });
      } else {
        // wyślij statystykę pageview scrolboost vpv. pageviewType ma wartość "overlay"
        // gdy nie mamy doładowania i "scrollboost" gdy następuje doładowanie, ALE dla pseudo dynamicznych stron AS ma byc jednak scrollboost ;) - ustalone z Salestube !
        wp_content_selector = document.getElementById(lastReportedSlug);

        WP.push(function () {
          WP.stat.show(
            wp_sn,
            {
              category: 'pageviewVpv',
              label: 'scrollboost',
              asproduct: asproduct,
              asorderid: asorderid,
              asname: asname,
            },
            'pageviewVpv'
          );
        });

        //sendStats('pageviewVpv', 'scrollboost', activeSlug); // musi isć dot ptrzed ustawieniem wp_content_selector

        unloadData(lastReportedSlug);

        if (typeof statData == 'undefined' && !statData.length) {
          console.log(
            '%c brakuje statData lub brak danych do statystyk ga360',
            'background: #553311; padding: 3px; color: #ffaaaa'
          );
          return;
        }

        wp_dot_addparams.ctags = statData[activeSlug].tags;
        WP.push(function () {
          WP.pwp.viewPage({
            ga: {
              event: 'pageviewVpv',
              page: {
                pageviewType: 'scrollboost',
                error404: basicStatData.ga.page.error404,
                templateType: 'AS',
              },
              site: {
                name: basicStatData.ga.site.name,
                version: basicStatData.ga.site.version,
                cms: basicStatData.ga.site.cms,
                as: asGA30,
                frontendPlatform: 'other',
              },
              content: {
                title: statData[activeSlug].title,
                category: {
                  IAB: statData[activeSlug].category.IAB,
                  Gemius: statData[activeSlug].category.Gemius,
                },
                tags: statData[activeSlug].tags,
              },
              //"content": statData[activeSlug]
            },
          });
        });
      }

      lastReportedSlug = activeSlug;
      reportSlugTS = -1;
    },
    // ----- }

    changeActiveSlug = function (slug) {
      activeSlug = slug;

      if (document.location.search.length > 0) {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          BASE_URL + slug + document.location.search
        );
      } else {
        history.pushState(
          {
            id: slug,
          },
          $(this).attr('title'),
          BASE_URL + slug
        );
      }
      var subtitle = statData[activeSlug].title
        ? statData[activeSlug].title + ' - '
        : '';

      if (document.querySelector('title') && statData[activeSlug]) {
        document.querySelector('title').innerText = subtitle + defaultSiteTitle;
      }
      if (
        document.querySelector('meta[property="og:title"]') &&
        statData[activeSlug]
      ) {
        document.querySelector('meta[property="og:title"]').content =
          subtitle + defaultSiteTitle;
      }
      if (
        document.querySelector('meta[name="twitter:title"]') &&
        statData[activeSlug]
      ) {
        document.querySelector('meta[name="twitter:title"]').content =
          subtitle + defaultSiteTitle;
      }

      if (lastReportedSlug === null) {
        // jeżeli pierwsze wejście to wysyłaj pageview od razu
        reportSlug();
      } else if (lastReportedSlug != slug) {
        // jeżeli zmiana sluga to wysyłaj po sekundzie statystykę

        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
        reportSlugTS = setTimeout(reportSlug, 1000);
      } else {
        if (reportSlugTS > -1) clearTimeout(reportSlugTS);
      }
    },
    // Sprawdzanie numeru sluga, domyślnie aktualnego
    getActiveSlugIndex = function (slug) {
      if (!slug) slug = activeSlug;
      return $('.__js_stats_wrapper[data-id=' + slug + ']').index();
    },
    // Sprawdzanie czy user zescrollował do sekcji. Jeżeli tak to ustawia pozycję w nawigacji
    sectionScrollCheck = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, 200);

      var $W = $(window),
        topScroll = $W.scrollTop(),
        wHeight = $W.height();

      // if (topScroll > $('.header').height()) {
      //   $('.header').addClass('header--bg');
      // } else {
      //   $('.header').removeClass('header--bg');
      // }

      // wymuszenie strony głównej
      if (
        topScroll <= customOffset &&
        document.location.pathname.split('/').pop().length < 1
      ) {
        changeActiveSlug($sections.eq(0).attr('id'));
        return;
      }

      for (var i = 0; i < $sections.length; i++) {
        var $section = $sections.eq(i),
          sectionOffset = $section.offset().top,
          sectionHeight = $section.height();

        if (
          activeSlug != $section.attr('id') &&
          topScroll > sectionOffset - customOffset &&
          topScroll < sectionOffset + sectionHeight - customOffset
        ) {
          changeActiveSlug($section.attr('id'));

          break;
        }
      }
    },
    sectionScrollCheckTS = null,
    // Inicjalizacja metod dla obsługi nawigacji - clików i sprawdzanie scrolla, dodanie flagi podczas skrolowania
    initNavigation = function () {
      sectionScrollCheckTS = setTimeout(sectionScrollCheck, animationTime + 25);
    };

  if (initSlug.length > 1) {
    setTimeout(scrollOnStart, 800);
  }
})();
